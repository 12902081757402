/*=========================================
	++ common.js ++ Ver.1.6.1
	Last update 2020.9.10
==========================================*/
/*
* 2020  9/10 全体を一新
* 2019  5/ 7  *object-fit-images 追加
* 2018 11/20  *ブラウザ判定処理を一新
* 2018 10/25  *jQuery 3系 に対応

*/

'usestrict';



//=====================================
//	Detecting Browser & Device Ver.1.4.2
//============================================

//++++++++++++ モバイル/タブレット/ブラウザ判定処理 ++++++++++++

//ブラウザ/デバイス 種別
var isMobile = false,
	isTablet = false,
	isRetina = false,
	isMobileTablet = false,
	isAndroid = false;

//モバイル　タブレット　判定
//https://w3g.jp/blog/js_browser_sniffing2015
var _ua = (function (u) {
	return {
		Tablet: (u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1) ||
			u.indexOf("ipad") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") == -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1) ||
			u.indexOf("kindle") != -1 ||
			u.indexOf("silk") != -1 ||
			u.indexOf("playbook") != -1,
		Mobile: (u.indexOf("windows") != -1 && u.indexOf("phone") != -1) ||
			u.indexOf("iphone") != -1 ||
			u.indexOf("ipod") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") != -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1) |
			u.indexOf("blackberry") != -1
	};
})(window.navigator.userAgent.toLowerCase());

//モバイル
if (_ua.Mobile) {
	isMobile = true;
	isMobileTablet = true;
}
//タブレット
if (_ua.Tablet) {
	isTablet = true;
	isMobileTablet = true;
}

//Retina Display 判定
if (window.retina || window.devicePixelRatio > 1) {
	isRetina = true;
}

//Android 判定
if (/android[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
	isAndroid = true;
}




//============================================
//	デバイス　ブラウザ別の処理
//============================================

//++++++++++++ モバイル/タブレット クラス追加 ++++++++++++

$(function () {
	if (isMobile) {
		//HTMLにclass isMobileを追加
		$('html').addClass('isMobile');
		$('html').addClass('isMobileTablet');
	} else if (isTablet) {
		//HTMLにclass isTabletを追加
		$('html').addClass('isTablet');
		$('html').addClass('isMobileTablet');
	}
	//Retina Display isRetina クラス追加
	if (isRetina) {
		$('html').addClass('isRetina');
	}
});





//============================================
//	汎用スクリプト 
//============================================


//++++++ Copyright ++++++
// Copyright <span class="copyYear">&copy;</span>

$(function () {
	var date = new Date();
	var copyYear = date.getFullYear();
	copyYear = '&copy;' + copyYear;
	$('.copyYear').html(copyYear);
});




//============================================
//	アンカーリンク　スクロール
//============================================
$(function () {

	var TABLET_WIDTH = 768;
	//ウィンドウ幅
	var window_width = window.innerWidth;

	var nav_height = 80;
	if (window_width >= TABLET_WIDTH) {
		//PC
		nav_height = 160;
	}


	var target_parm = '';
	var match = location.search.match(/target=(.*?)(&|$)/);
	if (match) {
		target_parm = decodeURIComponent(match[1]);
		target_parm = '#' + target_parm;
	}
	if (target_parm.length) {
		setTimeout(function () {
			var targetOffset = $(target_parm).offset().top - nav_height;
			$('html,body').animate({
				scrollTop: targetOffset
			}, 800);

			setTimeout(function () {
				var targetOffset_next = $(target_parm).offset().top - nav_height;
				$('html,body').animate({
					scrollTop: targetOffset_next
				}, 100);

			}, 800);
			console.log('@@' + targetOffset)
			return false;
		}, 800);

	}

});


function targetScroll() {

	var TABLET_WIDTH = 768;
	//ウィンドウ幅
	var window_width = window.innerWidth;

	var nav_height = 110;
	if (window_width >= TABLET_WIDTH) {
		nav_height = 100;
	}



	var target_parm = '';
	var match = location.search.match(/target=(.*?)(&|$)/);
	if (match) {
		target_parm = decodeURIComponent(match[1]);
		target_parm = '#' + target_parm;
	}
	if (target_parm.length) {
		setTimeout(function () {
			var targetOffset = $(target_parm).offset().top - nav_height;
			$('html,body').animate({
				scrollTop: targetOffset
			}, 800);
			console.log('@@' + targetOffset)
			return false;
		}, 800);

	}



}

//+++++++ 外部リンク判定 ++++++
$(function () {
	/*
		jQuery('a[href^=http]')
			.not('[href*="' + location.hostname + '"]')
			.attr({
				target: "_blank"
			})
			.addClass("outgoing");

		$('.mn_link a[href^=http]').each(function () {
			$(this).not('[href*="' + location.hostname + '"]').attr({
				target: "_blank"
			}).addClass("outgoing");

		})*/
});


//============================================
//	ページ内　アンカーナビ　リンク
//============================================
$(function () {
	//ページ内アンカーは　囲みのDIVに class js-anc-navi 追加
	//  a タグは通常アンカー　<a href="#anc">リンク</a>

	//モバイル判定
	var TABLET_WIDTH = 768;
	//ウィンドウ幅
	var window_width = window.innerWidth;

	//++++ 固定ナビの高さ分ずらす設定  +++
	//モバイル
	var nav_height = 80;
	if (window_width >= TABLET_WIDTH) {
		//PC
		nav_height = 190;
	}

	//aタグクリックで発火
	$('.js-anc-navi a').on('click', function () {

		//a タグの href 取得
		var anc_href = $(this).attr('href');

		//初期ページローディングの遅れ対策
		setTimeout(function () {
			var targetOffset = $(anc_href).offset().top - nav_height;
			$('html,body').animate({
				scrollTop: targetOffset
			}, 800);

			//アンカーズレ　防止　もう一回実行
			setTimeout(function () {
				var targetOffset = $(anc_href).offset().top - nav_height;
				$('html,body').animate({
					scrollTop: targetOffset
				}, 100);
				return false;

			}, 800);

		}, 400);
		//リンクをたどらせない
		return false;
	});
});


// // ページ遷移先　リンク位置調整
// $(window).on('load', function () {
// 	let url = location.href; // URLを取得
// 	let tabWidth = 768;
// 	let navHeight = 80;

// 	if (window_width >= tabWidth) {
// 		//PC
// 		navHeight = 210;
// 	}

// 	if (url.indexOf("?target=") != -1) { // URLに#が含まれていたら
// 		let id = url.split("?target="); // #で前後に分割
// 		let $target = $('?target=' + id[id.length - 1]); // #の後の名前と同じid名の要素を取得

// 		if ($target.length) {
// 			let position = $target.offset().top - navHeight; // idの位置を取得

// 			$("html, body").animate({ // 先の要素の位置までスクロール
// 				scrollTop: position
// 			}, 400);
// 			return false;
// 		}
// 	}
// });






//============================================
//	グローバルナビ　現在地
//============================================

$(function () {

	//URLを取得
	var my_path = location.pathname;


	//カテゴリごとの変数
	var is_home, is_nav1, is_nav2, is_nav3, is_nav4, is_nav5, is_nav6, is_nav7 = false;

	//@@@@@@ カテゴリごとのURL規則 @@@@@

	//*** nav1 ***/
	var regex_nav1 = /^\/kure\//i;

	//*** nav2***/
	var regex_nav2 = /^\/living\//i;

	//*** nav3 ***/
	var regex_nav3 = /^\/works\//i;

	//*** nav4 ***/
	var regex_nav4 = /^\/parenting\//i;

	//*** nav5 ***/
	var regex_nav5 = /^\/read\//i;

	//*** nav6 ***/
	var regex_nav6 = /^\/event\//i;

	//*** nav7 ***/
	var regex_nav7 = /^\/support\//i;


	//*** HOME ***/
	//var regex_home = ($('body').attr('id') == 'root');
	var regex_home = ($('body').hasClass('Index'));


	//@@@@@@ 条件をテスト @@@@@@

	if (my_path.match(regex_nav1)) {
		is_nav1 = true;
	} else if (my_path.match(regex_nav2)) {
		is_nav2 = true;
	} else if (my_path.match(regex_nav3)) {
		is_nav3 = true;
	} else if (my_path.match(regex_nav4)) {
		is_nav4 = true;
	} else if (my_path.match(regex_nav5)) {
		is_nav5 = true;
	} else if (my_path.match(regex_nav6)) {
		is_nav6 = true;
	} else if (my_path.match(regex_nav7)) {
		is_nav7 = true;
	} else if (regex_home) {
		is_home = true;
	}


	//@@@@@@ 条件にあったら　.activeを追加　@@@@@@

	//クラス activeを追加
	if (is_home) {
		$('a.gnav00').addClass('hilight');
	} else if (is_nav1) {
		$('a.gnav01').addClass('hilight');
	} else if (is_nav2) {
		$('a.gnav02').addClass('hilight');
	} else if (is_nav3) {
		$('a.gnav03').addClass('hilight');
	} else if (is_nav4) {
		$('a.gnav04').addClass('hilight');
	} else if (is_nav5) {
		$('a.gnav05').addClass('hilight');
	} else if (is_nav6) {
		$('a.gnav06').addClass('hilight');
	} else if (is_nav7) {
		$('a.gnav07').addClass('hilight');
	}

});






//+++++++ Footer PageTOP ++++++

$(function () {

	$('.scroll_top_btn').on('click', function () {
		var targetOffset = $('Main').offset().top;
		//alert(targetOffset);
		$('html,body').animate({
			//scrollTop: targetOffset
			scrollTop: 0
		}, 800);
	});

	var TABLET_WIDTH = 768;
	//ウィンドウ幅
	var window_width = window.innerWidth;

	$(window).on('scroll', function () {

		var scroll_top = $(window).scrollTop();
		var hide_point = '';
		if (window_width >= TABLET_WIDTH) {
			hide_point = 600;
		} else {
			hide_point = 300;
		}

		if (hide_point < scroll_top) {
			$('.scroll_top_btn').fadeIn('fast');
		} else {
			$('.scroll_top_btn').fadeOut('fast');
		}
		//console.log(scroll_top)

	})


});



$(function () {
	$('.venobox').venobox({
		infinigall: true,
		numeratio: true,
	});
});




$(function () {
	$(".menubtn").click(function () {
		$(this).toggleClass('active');
		$('body').toggleClass('no-scroll');
		$("#menu").toggleClass('panelactive');
	});

	$("#spnav a").click(function () {
		$(".menubtn").removeClass('active');
		$('body').removeClass('no-scroll');
		$("#menu").removeClass('panelactive');
	});
})


$(function () {
	$(".snav_cat a").click(function () {
		$(this).toggleClass('active');
		$('.snav_sub').toggleClass('show');
	});


})



//========= 検索 ================

$(function () {
	$('.search_tab_item').on('click', function () {
		const checkId = $(this).attr('id');

		$('.search_tab_item').removeClass('selected');
		$(this).addClass('selected');

		if ($('.search_cat_list').hasClass(checkId)) {
			// console.log('OK');
			$('.search_cat_list').removeClass('show');
			$(`.search_cat_list.${checkId}`).addClass('show');
		}
	});
});

// 商品検索モーダル
$(function () {
	$('.open-modal').on('click', function () {
		$('#modal_search').addClass('open');
		$('#modal_search .modal_bg').addClass('show');
		$("html,body").css("overflow", "hidden");
	});

	$('.close-modal').on('click', function () {
		$('#modal_search').removeClass('open');
		$('#modal_search .modal_bg').removeClass('show');
		$("html,body").removeAttr("style");
	});
});



// ボタン
$(function () {
	$('.btn--select').on('click', function () {
		$(this).toggleClass('selected');
		return false;
	});

	$('.btn--radio').on('click', function () {
		//const getId = $(this).attr('id');

		$('.btn--radio').removeClass('selected');
		$(this).addClass('selected');


		/*console.log(getId);
		if ($('.show_btn_list').hasClass(getId)) {
			console.log('OK');
			$('.show_btn_list').removeClass('show');
			$(`.show_btn_list.${getId}`).addClass('show');
		}*/

		return false;
	});
});

//検索カテゴリ切り替え
$(function () {
	$('.js-type-toggle-btn').on('click', function () {

		$('.togle_SearchType').removeClass('toggle_show ');

		const target = $(this).data('target');
		console.log(target)

		$('.js-type-toggle-btn').removeClass('selected');
		$(this).addClass('selected');

		$('.togle_SearchType').fadeOut(0);
		$(target).fadeIn('slow');


	});
});



$(function () {
	var $grid = $('.grid'),
		emptyCells = [],
		i;

	for (i = 0; i < $grid.find('.cell').length; i++) {
		emptyCells.push($('<div>', {
			class: 'cell is-empty'
		}));
	}
	$grid.append(emptyCells);
});



$(function () {
	var $grid = $('.report_column'),
		emptyCells = [],
		i;

	for (i = 0; i < $grid.find('.report_card').length; i++) {
		emptyCells.push($('<div>', {
			class: 'report_card is-empty'
		}));
	}
	$grid.append(emptyCells);
});



$(function () {
	var $grid = $('.exhibition_column'),
		emptyCells = [],
		i;

	for (i = 0; i < $grid.find('.exhibition_card').length; i++) {
		emptyCells.push($('<div>', {
			class: 'exhibition_card is-empty'
		}));
	}
	$grid.append(emptyCells);
});


$(function () {
	var $grid = $('.square_column'),
		emptyCells = [],
		i;

	for (i = 0; i < $grid.find('.square_card').length; i++) {
		emptyCells.push($('<div>', {
			class: 'square_card is-empty'
		}));
	}
	$grid.append(emptyCells);
});


$(function () {
	var $grid = $('.entry_column'),
		emptyCells = [],
		i;

	for (i = 0; i < $grid.find('.entry_title_only').length; i++) {
		emptyCells.push($('<div>', {
			class: 'entry_title_only is-empty'
		}));
	}
	$grid.append(emptyCells);
});

$(function () {
	var $grid = $('.commun_column'),
		emptyCells = [],
		i;

	for (i = 0; i < $grid.find('.commun_card').length; i++) {
		emptyCells.push($('<div>', {
			class: 'commun_card is-empty'
		}));
	}
	$grid.append(emptyCells);
});








$(function () {
	//swiper 768以下で起動
	var swiper;
	$(window).on('load resize', function () {
		var w = $(window).width();
		if (w <= 768) {
			if (swiper) {
				return;
			} else {
				swiper = new Swiper('.swiper-sp-collection', {
					loop: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
				});
			}
		} else {
			if (swiper) {
				swiper.destroy();
				swiper = undefined;
			}
		}
	});
});





$(function () {
	var navi = $('.fixed_nav');
	//スクロールしてページトップから100に達したらクラスを付与する
	$(window).on('load scroll', function () {
		if ($(this).scrollTop() > 200) {
			navi.addClass('fixed');
		} else {
			navi.removeClass('fixed');
		}
	});
});


$(function () {

	/* ここには、表示するリストの数を指定します。 */
	var moreNum = 4;

	/* 表示するリストの数以降のリストを隠しておきます。 */
	$('.entry_card:nth-child(n + ' + (moreNum + 1) + ')').addClass('is-hidden');

	/* 全てのリストを表示したら「もっとみる」ボタンをフェードアウトします。 */
	$('.list-btn').on('click', function () {
		$('.entry_card.is-hidden').slice(0, moreNum).removeClass('is-hidden');
		if ($('.entry_card.is-hidden').length == 0) {
			$('.list-btn').fadeOut();
		}
	});

	/* リストの数が、表示するリストの数以下だった場合、「もっとみる」ボタンを非表示にします。 */
	$(function () {
		var list = $(".entry_card").length;
		if (list < moreNum) {
			$('.list-btn').addClass('is-btn-hidden');
		}
	});
});